import React from 'react';
import logo from './logo.svg';
import './App.css';
import SalesOrderList from './features/sales/SalesOrderList';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SalesOrderDetail from './features/sales/SalesOrderDetail';
import SalesOrderScan from './features/sales/SalesOrderScan';
import AppLayout from './features/layout/AppLayout';
import SalesOrderCreate from './features/sales/SalesOrderCreate';
import LoginForm from './features/auth/LoginForm';
import InfoScan from './features/info/InfoScan';
import SalesOrderConfirm from './features/sales/SalesOrderConfirm';
import InfoDetail from './features/info/InfoDetail';

function Home() {
  window.location.href = "/login"
  return <></>
}


function App() {
  return (
      <Routes>
        <Route path="/sales-orders/scan/:id?" element={<SalesOrderScan />} />
        <Route path="/sales-orders/scan/:orderId/:scannedProduct/confirm" element={<SalesOrderConfirm />} />
        <Route path="/sales-orders/:id" element={<SalesOrderDetail />} />
        <Route path="/sales-orders/create" element={<SalesOrderCreate />} />
        <Route path="/sales-orders/" element={<SalesOrderList />} />

        <Route path="/info/" element={<InfoScan />} />
        <Route path="/info/:id" element={<InfoDetail />} />
        
        <Route path="/login" element={<LoginForm />} />
        
        <Route path="/" element={<Home />} />
      </Routes>
  );
}

export default App;
