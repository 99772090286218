import { AppBar, Grid, Divider, Button, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance";
import AppLayout from "../layout/AppLayout";
import { InputField } from "../../components/Form/InputField"
import { StaticSelectField } from "../../components/Form/StaticSelectField"
import { AsyncSelectField } from "../../components/Form/AsyncSelectField"
import * as yup from "yup"
import { FieldValues, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const schema = yup
  .object({
    project: yup
      .object()
      .required("Dit veld is verplicht"),
    order_type: yup
      .object()
      .required("Dit veld is verplicht"),
    description: yup
      .string()
      .required("Dit veld is verplicht"),
  })
  .required()

export default function SalesOrderCreate() {
  const params = useParams();
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitting },
    // @ts-ignore
  } = useForm({ resolver: yupResolver(schema) })

  const fetchProjects = React.useCallback(async (filter: string): Promise<{ label: string, value: string }[]> => {
    const { data } = await axiosInstance.get('/visma-projects/', {
      params: {
        query: filter,
      }
    });

    return data.results.map((x: any) => ({
      label: x.description,
      value: x.internal_id,
      customer: x.customer_number,
    }));
  }, []);

  async function createSalesOrder(values: FieldValues) {
    const results = await axiosInstance.post(
      `/visma-sales-orders/create_visma_sales_order/`,
      {
        customer: values?.project?.customer,
        project: values?.project?.value,
        description: values?.description,
        order_type: values?.order_type?.value,
        is_created_from_scanner: true,
      }
    ).then(() => navigate('/sales-orders'))
  }

  return (
    <AppLayout>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => navigate(`/sales-orders`)}>
            <ArrowBackIosNewIcon sx={{ color: '#FFF' }} fontSize="inherit" />
            <Typography variant="body2" sx={{ textAlign: 'left', color: '#FFF' }}>
              Terug
            </Typography>
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', ml: -5 }}>
            Verkooporder toevoegen
          </Typography>
        </Toolbar>
      </AppBar>

      <form style={{ margin: 10 }} onSubmit={handleSubmit(createSalesOrder)}>
        <Grid container direction="column" spacing="8">
          <Grid item>
            <AsyncSelectField
              isClearable={false}
              error={errors.project}
              control={control}
              label={"Project"}
              loadOptions={fetchProjects}
              {...register("project")}
              isRequired
              name="project"
            />
          </Grid>

          <Grid item>
            <StaticSelectField
              {...register("order_type")}
              isClearable
              isRequired
              label={"Soort"}
              control={control}
              name="order_type"
              error={errors.order_type}
              options={[
                { label: 'MP - Materiaal op projecten', value: 'MP' },
                { label: 'SO - Service order', value: 'SO' },
                { label: 'RP - Retour project', value: 'RP' },
              ]}
            />
          </Grid>

          <Grid item>
            <InputField
              label={"Omschrijving"}
              isRequired
              control={control}
              error={errors.description}
              {...register("description")}
            />
          </Grid>

          <Grid item sx={{ marginTop: 0 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              Opslaan
            </Button>
          </Grid>
        </Grid>
      </form>
    </AppLayout>
  )
}