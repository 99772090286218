import React from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { Controller } from "react-hook-form";

export const StaticSelectField = ({
  name,
  error,
  label,
  options,
  control,
  isClearable,
  isRequired = false,
  disabled = false,
}: any) => {

  if (!options || !options.length) {
    return <></>
  }

  return (
    <FormControl fullWidth>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            value={field.value || null}
            disableClearable={!isClearable}
            getOptionLabel={(option) => option.label}
            options={options}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField 
              error={!!error} 
              helperText={error ? error.message : null} 
              label={label} 
              {...params} 
              inputProps={{...params.inputProps, ...{
                'data-testid': field.name
              }}} 
              InputLabelProps={{
                required: isRequired
              }}
            />}
            onChange={(_event, value) => {
              field.onChange(value);
            }}
          />
				)}
        name={name}
        control={control}
      />
    </FormControl>
  );
}