import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Html5Qrcode } from "html5-qrcode";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance";
import Html5QrcodePlugin from "../Html5QrcodePlugin";
import AppLayout from "../layout/AppLayout";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


export default function SalesOrderScan() {
	const navigate = useNavigate();
	const params = useParams();

	const { id: orderId } = params;

	const onNewScanResult = (decodedText: any, decodedResult: any) => {
		const match = decodedText.match(/\{\{product\=\"(.+)\"\}\}/);
		navigate(`/sales-orders/scan/${orderId}/${match[1]}/confirm`)
	};

	return (
		<AppLayout>
			<AppBar position="static">
				<Toolbar>
					<IconButton onClick={() => navigate(`/sales-orders/${orderId}`)}>
						<ArrowBackIosNewIcon sx={{ color: '#FFF' }} fontSize="inherit" />
						<Typography variant="body2" sx={{ textAlign: 'left', color: '#FFF' }}>
							Details
						</Typography>
					</IconButton>
					<Typography variant="h6" sx={{ flex: 1, textAlign: 'center', ml: -7 }}>
						Scanner
					</Typography>
				</Toolbar>
			</AppBar>

			<Html5QrcodePlugin
				fps={10}
				qrbox={250}
				disableFlip={false}
				qrCodeSuccessCallback={onNewScanResult}
			/>
		</AppLayout>
	)
}