import { AppBar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { Html5Qrcode } from "html5-qrcode";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance";
import Html5QrcodePlugin from "../Html5QrcodePlugin";
import AppLayout from "../layout/AppLayout";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


export default function InfoDetail() {
  const params = useParams();
  const navigate = useNavigate();

  const [product, setProduct]: any = React.useState(null);

  const { id } = params;

  async function fetchProduct(id: any) {
    const result = await axiosInstance.get(`/visma_api/get_inventory_item?pk=${id}`, {
      headers: {
        // TODO: Make this not hardcoded :)
        tokenhoi: "ebd3239e177e9712d496d20811870ccd00d28d68"
      }
    });

    if (result) {
      setProduct(result.data);
    }
  }

  React.useEffect(() => {
    fetchProduct(id);
  }, [id])

  return (
    <AppLayout>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => navigate(`/info`)}>
            <ArrowBackIosNewIcon sx={{ color: '#FFF' }} fontSize="inherit" />
            <Typography variant="body2" sx={{ textAlign: 'left', color: '#FFF' }}>
              Terug
            </Typography>
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', ml: -10 }}>
            Detail
          </Typography>
        </Toolbar>
      </AppBar>

      {/* <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
            Detail
          </Typography>
        </Toolbar>
      </AppBar> */}

      {/* Detail */}
      <Box sx={{ m: 2 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
          Product: {product && product?.inventoryNumber} - {product?.description}
        </Typography>
        <Typography variant='body2'>
          Beschikbaar: {product && product.warehouseDetails[0].available} {product && product.baseUnit}
        </Typography>
      </Box>
    </AppLayout>
  )
}
