import { AppBar, Avatar, Box, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance";
import Html5QrcodePlugin from "../Html5QrcodePlugin";
import AppLayout from "../layout/AppLayout";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import QrCodeIcon from '@mui/icons-material/QrCode';


export default function SalesOrderDetail() {
  const params = useParams();
  const [salesOrder, setSalesOrder] = useState<any>({});
  const [isFetching, setIsFetching] = useState(true);
  const [lines, setLines]: any = useState([]);

  const navigate = useNavigate()

  useEffect(() => {
    getSalesOrder(params.id);
  }, [params.id]);

  /**
   * Fetch sales orders
   */
  async function getSalesOrder(id: any) {
    setIsFetching(true);

    const results = await axiosInstance.get(`/visma-sales-orders/${id}/`);

    const resultLines = await axiosInstance.get(`/visma-sales-order-lines/?sales_order=${id}`);

    if (results) {
      setLines(resultLines.data.results);
      setSalesOrder(results.data);
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return <>Loading...</>
  }

  return (
    <AppLayout>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => navigate(`/sales-orders`)}>
            <ArrowBackIosNewIcon sx={{ color: '#FFF' }} fontSize="inherit" />
            <Typography variant="body2" sx={{ textAlign: 'left', color: '#FFF' }}>
              Verkooporders
            </Typography>
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', ml: -15 }}>
            Details
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ m: 2 }}>
        <Button startIcon={<QrCodeIcon />} variant='outlined' fullWidth onClick={() => navigate(`/sales-orders/scan/${salesOrder.id}`)}>
          Artikel scannen
        </Button>
      </Box>

      <Box sx={{ m: 2 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
          {salesOrder?.customer_name} 
        </Typography>
        <Typography variant='body2'>
          Omschrijving: {salesOrder?.description}
        </Typography>
        <Typography variant='body2'> 
          Ordernummer: {salesOrder?.order_no} ({salesOrder?.order_type})
        </Typography> 
      </Box>

      <Typography sx={{ ml: 2, mb: 1 }}>
        Regels in verkooporder:
      </Typography>

      <TableContainer component={Paper} sx={{ height: '67vh', overflowY: 'auto' }}>
        <Table>
          <TableBody>
            {lines.map((row: any) => (
              <TableRow
                key={row.order_no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Avatar variant='square'>
                    {row.quantity_requested}
                  </Avatar>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{row.line_description}</Typography>
                  <Typography variant='body2'>Code: {row.product_number}</Typography>
                  <Typography variant='body2'>Besteld: {row.quantity_requested} {row.uom?.toLowerCase()}</Typography>
                  <Typography variant='body2'>Open: {row.quantity_open} {row.uom?.toLowerCase()}</Typography>
                  <Typography variant='body2'>Picked: {row.quantity_picked} {row.uom?.toLowerCase()}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AppLayout>
  )
}