import { AppBar, Avatar, Box, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance";
import AppLayout from "../layout/AppLayout";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import * as yup from "yup"
import { FieldValues, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InputNumberField } from "../../components/Form/InputNumberField"

const schema = yup
  .object({
    quantity: yup
      .number()
      .typeError('Een geldig getal is vereist')
      .required("Dit veld is verplicht")
      .min(1, "Minimum is 1")
  })
  .required()


export default function SalesOrderConfirm() {
  const { orderId, scannedProduct }: any = useParams()
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitting },
    // @ts-ignore
  } = useForm({ resolver: yupResolver(schema) })

  const [inventoryItem, setInventoryItem]: any = React.useState();

  async function getInventoryItem(product: any) {
    const response = await axiosInstance.post(`/visma-sales-orders/${orderId}/find_product/`, {
      scanned_product: product
    })

    setInventoryItem(response.data);
  }

  useEffect(() => {
    getInventoryItem(scannedProduct);
  }, [scannedProduct]);

  async function doSave(values: any) {
    // if (salesOrder.order_type !== 'RP') {
    //   if (inventoryItem && inventoryItem.warehouseDetails[0].available <= 0) {
    //     alert('Maximum is 0');
    //
    //     return;
    //   }

    // if (inventoryItem && values.quantity > inventoryItem.warehouseDetails[0]?.available) {
    //   alert(`Maximum is ${inventoryItem.warehouseDetails[0].available}`);
    //   return;
    // }

    axiosInstance.post(
      `/visma-sales-orders/${orderId}/scan/`,
      {
        quantity: values.quantity,
        scanned_product: scannedProduct,
        // line_number: item.lineNumber,
        uom: inventoryItem && inventoryItem.baseUnit,
        product_description: inventoryItem && inventoryItem.description,
        sales_order: orderId,
        // order_type: salesOrder.orderType,
        // line_number: lineNbr,
      }
    ).catch(err => {
      alert(JSON.stringify(err))
    }).then(() => navigate(`/sales-orders/${orderId}`))
  }

  return (
    <AppLayout>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => navigate(`/sales-orders/${orderId}`)}>
            <ArrowBackIosNewIcon sx={{ color: '#FFF' }} fontSize="inherit" />
            <Typography variant="body2" sx={{ textAlign: 'left', color: '#FFF' }}>
              Details
            </Typography>
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', ml: -7 }}>
            Confirm
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ m: 2 }}>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          {inventoryItem && inventoryItem.inventoryNumber} - {inventoryItem && inventoryItem.description}
        </Typography>
        <Typography variant='body2'>
          Open: {inventoryItem?.quantity_open}
        </Typography>
        <Typography variant='body2'>
          Benodigd: {inventoryItem?.quantity_requested}
        </Typography>
        <Typography variant='body2'>
          Picked: {inventoryItem?.quantity_picked}
        </Typography>
        <Typography variant='body2'>
          Beschikb.: {inventoryItem && inventoryItem.warehouseDetails && inventoryItem.warehouseDetails[0].available}
        </Typography>
      </Box>

      <form style={{ margin: 10 }} onSubmit={handleSubmit(doSave)}>
        <Grid container direction="column" spacing="8">
          <Grid item>
            <InputNumberField
              control={control}
              label={"Aantal"}
              isRequired
              error={errors.quantity}
              {...register("quantity")}
            />
          </Grid>

          <Grid item sx={{ marginTop: 0 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              Opslaan
            </Button>
          </Grid>
        </Grid>
      </form>
    </AppLayout>
  )
}