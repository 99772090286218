import { AppBar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { Html5Qrcode } from "html5-qrcode";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance";
import Html5QrcodePlugin from "../Html5QrcodePlugin";
import AppLayout from "../layout/AppLayout";


export default function InfoScan() {
  const navigate = useNavigate();

  const onNewScanResult = (decodedText: any, decodedResult: any) => {
		const match = decodedText.match(/\{\{product\=\"(.+)\"\}\}/);
		navigate(`/info/${match[1]}/`)
	};

  return (
    <AppLayout>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
            Scanner
          </Typography>
        </Toolbar>
      </AppBar>

      <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResult}
      />
    </AppLayout>
  )
}
