import { API_URL } from "./Constants";
import axios from "axios";

const axiosInstance = axios.create({ baseURL: API_URL })

const TOKEN_PREFIX = "solisplan-pwa"

export const ACCESS_TOKEN_NAME = `${TOKEN_PREFIX}_access_token`
export const REFRESH_TOKEN_NAME = `${TOKEN_PREFIX}_refresh_token`

const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_NAME)
const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_NAME)

// Request interceptor for API calls
axiosInstance.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`
  return request
})

// const refreshAuthLogic = (failedRequest: any) => {
//   return axiosInstance
//     .post(`${API_URL}/token/refresh/`, {
//       refresh: getRefreshToken(),
//     })
//     .then((response) => {
//       localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access)
//       localStorage.setItem(REFRESH_TOKEN_NAME, response.data.refresh)

//       failedRequest.response.config.headers[
//         "Authorization"
//       ] = `Bearer ${response.data.access}`

//       return Promise.resolve()
//     })
// }

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    // Redirect to login page
    if (
      error.response &&
      error.response.status === 401
    ) {
      window.location.href = '/login'
    }

    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      const response = await axios.post(`${API_URL}/token/refresh/`, {
        refresh: getRefreshToken()
      })

      localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access)
      localStorage.setItem(REFRESH_TOKEN_NAME, response.data.refresh)

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.data.access
      return axiosInstance(originalRequest)
    }
    return Promise.reject(error)
  }
)

export { axiosInstance }