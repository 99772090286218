import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import { Controller } from "react-hook-form";

interface IProps {
  name: string
  error?: object
  label?: string
  control: any
  isRequired?: boolean
  disabled?: boolean
  type?: string
}

const InputField = (props: IProps) => {
  const { name, label, control, isRequired, disabled, type } = props;

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, ref, name },
          fieldState: { error },
        }) => (
          <TextField
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            value={value || ''}
            inputRef={ref}
            disabled={disabled}
            error={!!error}
            helperText={error ? error.message : null}
            label={label}
            inputProps={{
              'data-testid': name
            }}
            InputLabelProps={{
              required: isRequired
            }}
          />
        )}
        // rules={{ required: isRequired }}
      />
    </FormControl>
  );
};

InputField.defaultProps = {
  isRequired: false,
  disabled: false,
};

export { InputField };