import { AppBar, Toolbar, IconButton, Stack, Box, Grid, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function AppLayout({ children }: any) {
  return (
    <>
      <Box>
        {children}
      </Box>

      <AppBar className="footer" position="sticky" color="primary">
        <Toolbar>
          <Grid container justifyContent='space-evenly'>
            <Grid item>
              <Link to="/sales-orders" style={{ color: '#FFF', textDecoration: 'none' }}>
                <Stack direction="column" alignItems="center">
                  <RemoveCircleOutlineIcon />
                  <Typography variant="body2">Uitslag</Typography>
                </Stack>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/info/" style={{ color: '#FFF', textDecoration: 'none' }}>
                <Stack direction="column" alignItems="center">
                  <InfoIcon />
                  <Typography variant="body2">Info</Typography>
                </Stack>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}