import { AppBar, Box, Avatar, Button, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance";
import AppLayout from "../layout/AppLayout";
import moment from 'moment'
import AddIcon from '@mui/icons-material/Add';


export default function SalesOrderList() {
  const [salesOrders, setSalesOrders] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    getSalesOrders();
  }, []);

  /**
   * Fetch sales orders
   */
  async function getSalesOrders() {
    setIsFetching(true);

    const results = await axiosInstance.get(`/visma-sales-orders/`);

    if (results) {
      // console.log(results.data);
      setSalesOrders(results.data.results);
      setIsFetching(false);
    }
  };
  
  return (
    <AppLayout>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
            Verkooporders
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ m: 2 }}>
        <Button startIcon={<AddIcon />} variant='outlined' fullWidth onClick={() => navigate(`/sales-orders/create`)}>
          Nieuwe uitslag
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ height: '80vh' }}>
        <Table>
          <TableBody>
            {salesOrders.map((row: any) => (
              <TableRow
                key={row.order_no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Avatar variant='square'>
                    {row.order_type}
                  </Avatar>
                </TableCell>
                <TableCell component="th" scope="row" onClick={() => navigate(`/sales-orders/${row.id}`)}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{row.customer_name}</Typography>
                  <Typography variant='body2'>{row.order_no}</Typography>
                  <Typography sx={{ color: 'gray' }} variant='body2'>{moment(row.date).format('D MMMM')}</Typography>
                  <Typography sx={{ color: 'gray' }} variant='body2'>{row.status}</Typography>
                  {/* {row.description} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AppLayout>
  )
}