import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router"
import * as yup from "yup"
import { FieldValues, useForm } from "react-hook-form"
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME, axiosInstance } from "../../axiosInstance"
import { Grid, Button, Box, Container } from "@mui/material";
import { InputField } from "../../components/Form/InputField"
import { PasswordField } from "../../components/Form/PasswordField"

const schema = yup
  .object({
    username: yup.string().required("Dit veld is verplicht"),
    password: yup.string().required("Dit veld is verplicht"),
  })
  .required()

export default function LoginForm() {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitting },
    // @ts-ignore
  } = useForm({ resolver: yupResolver(schema) })

  async function doLogin(values: FieldValues) {
    const response = await axiosInstance.post("/token/", {
      username: values.username,
      password: values.password,
    })

    // save tokens to storage
    localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access)
    localStorage.setItem(REFRESH_TOKEN_NAME, response.data.refresh)

    navigate('/sales-orders')
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container maxWidth="xs">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          Inloggen
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: 0, minWidth: 360, p: 2 }}>
            <form onSubmit={handleSubmit(doLogin)}>
              <Grid container direction="column" spacing="8">
                <Grid item>
                  <InputField
                    label={"Gebruikersnaam"}
                    control={control}
                    error={errors.username}
                    {...register("username")}
                  />
                </Grid>

                <Grid item>
                  <PasswordField
                    label={"Wachtwoord"}
                    control={control}
                    error={errors.password}
                    {...register("password")}
                  />
                </Grid>

                <Grid item sx={{ marginTop: 0 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Inloggen
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}