import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import { Controller } from "react-hook-form";

const InputNumberField = (props: any) => {
  const { name, error, label, control, isRequired, disabled } = props;

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <TextField
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            value={value || ''}
            inputRef={ref}
            disabled={disabled}
            error={!!error}
            helperText={error ? error.message : null}
            label={label}
            type="number"
            inputProps={{
              'data-testid': name
            }}
            InputLabelProps={{
              required: isRequired
            }}
          />
        )}
        rules={{ required: isRequired }}
      />
    </FormControl>
  );
};

InputNumberField.defaultProps = {
  isRequired: false,
  disabled: false,
};

export { InputNumberField };