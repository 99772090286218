import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React from 'react';

import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from "react-hook-form";

export const AsyncSelectField = ({
  name,
  error,
  label,
  control,
  isClearable,
  isRequired = false,
  loadOptions,
  disabled = false,
  ...inputProps
}: any) => {

  // const context = useFormContext()

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)

  function fetchData(inputValue?: string) {
    setLoading(true)

    if (inputValue) {
      loadOptions(inputValue).then((response: []) => {
        setOptions(response)
        setLoading(false)
      })
    } else {
      loadOptions().then((response: []) => {
        setOptions(response)
        setLoading(false)
      })
    }
  }

  // Fetch after changing the input
  useEffect(() => {
    if (open) {
      fetchData(inputValue)
    }
  }, [open, inputValue])

  function onOpen() {
    setInputValue('')

    setTimeout(() => {
      setOpen(true)  
    });
  }

  function onClose() {
    setOpen(false)
  }
 
  return (
    <FormControl fullWidth>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            open={open}
            onOpen={() => onOpen()}
            onClose={() => onClose()}
            value={field.value || null}
            disableClearable={loading || !isClearable}
            loading={loading}
            filterOptions={(x) => x}
            disabled={disabled}
            getOptionLabel={(option) => option.label}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField 
              // required={context?.schema?.fields[name]?.exclusiveTests?.required || false}
              label={label} 
              error={!!error} 
              helperText={error ? error.message : null} 
              {...params} 
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{...params.inputProps, ...{
                'data-testid': field.name
              }}}
              InputLabelProps={{
                required: isRequired
              }}
            />}
            onChange={(_event, value) => {
              field.onChange(value);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
          />
        )}
        name={name}
        control={control}
      />
    </FormControl>
  );
}