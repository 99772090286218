import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        sx: {
          boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2);",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
        disableElevation: true,
        sx: {
          textTransform: "none",
          // fontWeight: 'bold',
          paddingX: 2,
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        // margin: 'dense',
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
        // sx: {
        //   fieldset: {
        //     borderColor: "#d3dae4",
        //   },
        // },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {},
        },
      },
      defaultProps: {
        sx: {
          textTransform: "none",
          fontSize: "0.9rem",
          letterSpacing: "inherit",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    // MuiDrawer: {
    //   styleOverrides: {
    //     paper: {
    //       backgroundColor: "#111",
    //       color: "#eee",
    //       "&, & .MuiListItemIcon-root": {
    //         color: "#eee",
    //         minWidth: 32,
    //       },
    //       width: 200,
    //     },
    //     root: {
    //       // hover states
    //       "&& .MuiListItem-root:hover": {
    //         backgroundColor: grey[800],
    //         // '&, & .MuiListItemIcon-root': {
    //         //   color: 'yellow',
    //         // },
    //       },
    //       "&& .Mui-selected, && .Mui-selected:hover": {
    //         backgroundColor: mode === "light" ? orange[600] : amber[500],
    //         "&, & .MuiListItemIcon-root": {
    //           color: "#07203C",
    //         },
    //       },
    //     },
    //   },
  },
});

export default theme;